import { gql } from '@/__generated__';
import { useAppLoader } from './loader';
import { useFragment } from '@apollo/client';
import { AuthInfo } from '@/amplify/get-auth-info';

const MeMinimalFragment = gql(`
fragment MeMinimalFragment on User {
  id
  fullName
  username
}
`);

/**
 *
 * Can be used as a faster alternative to `useMe` to get minimal user data without suspends
 *
 */
export const useAuthInfo = (): AuthInfo => {
  const { authInfo } = useAppLoader();

  const { data } = useFragment({
    fragment: MeMinimalFragment,
    from: {
      __ref: `User:${authInfo.id}`,
      __typename: 'User',
    },
  });

  return {
    id: authInfo.id,
    fullName: data.fullName || authInfo.fullName,
    username: data.username || authInfo.username,
  };
};
